<template>
	<div>
		<h5>Transaction Information</h5>
		<a-divider />
		<a-form-model ref="step1" :model="step1" :rules="rules">
			<a-form-model-item prop="offerType" class="mb-0">
				<template slot="label">
					Signing Method
					<a-tooltip overlayClassName="change-tooltip-color" placement="right" style="{background: #3f3455}">
						<template slot="title" style="width: 400px;">
							Select e-signature if at least one party needs to sign digitally.
						</template>
						<a-icon type="question-circle" style="font-size: 14px; color: black; margin-left: 2px;" />
					</a-tooltip>
				</template>
				<a-radio-group v-model="step1.isManual">
					<a-radio-button :value="false">
						<a-icon type="file-protect" /> E-Signature
					</a-radio-button>
					<a-radio-button :value="true">
						<a-icon type="highlight" /> Print &amp; Sign
					</a-radio-button>
				</a-radio-group>
			</a-form-model-item>

			<p v-if="!integrated.active && !step1.isManual" class="aC py-1 mb-0 bg-danger text-white rounded"
				style="display:inline-flex; max-width:600px">
				<a-icon class="text-lg" type="warning" />
				<span class="px-1 py-2">
					You are not currently integrated with our signing service.
					Please check your <router-link class="text-primary" to="/settings?docusign">settings</router-link>
					to integrate
					with a signing service.
					Alternatively, you can choose the <strong>Print &amp; Sign</strong> option.
				</span>
			</p>
			<p v-if="step1.isManual" class="aC py-1 mb-0 bg-med-gray text-white rounded bg-info"
				style="display:inline-flex; max-width:600px">
				<a-icon class="text-lg" type="note" />
				<span class="px-1 py-2">
					Please note that this will be a Manual Transaction. <br />You will have to Scan and Upload the
					signed
					contract.<br />
				</span>
			</p>

			<a-form-model-item label="Select a Transaction Type to get started" class="mt-3">
				<a-select :size="$mq === 'lg' ? null : 'large'" v-model="step1.type" :disabled="disableType"
					style="max-width:200px;" @change="transactionTypeChange">
					<a-select-option :value="t.value" v-for="t in transactionTypes"
						:key="t.value">{{ t.label }}</a-select-option>
				</a-select>
			</a-form-model-item>

			<template v-if="step1.type === 'purchase'">


				<a-form-model-item label="Offer Type" prop="offerType">
					<a-radio-group v-model="step1.offerType">
						<a-radio-button value="firm">
							Firm
						</a-radio-button>
						<a-radio-button value="conditional">
							Conditional
						</a-radio-button>
					</a-radio-group>
				</a-form-model-item>
				<a-form-model-item label="Cooling Off Period (in Days)" prop="grace"
					v-show="step1.offerType === 'conditional'">
					<a-input-number :min="1" :max="730" v-model="step1.grace" />
				</a-form-model-item>
			</template>

			<a-form-model-item :label="`Please choose the Agreement of ${cType} Contract template`" prop="template">
				<a-select :size="$mq === 'lg' ? null : 'large'" option-label-prop="label" v-model="step1.template"
					style="min-width:50%; max-width: 500px;">
					<a-select-option :value="template.id" v-for="template in transactionsFiltered" :key="template.id"
						:label="template.name">
						<div class="dF aC template-list">
							<div>
								<i class="fe fe-file-text" style="font-size:15px;" />
							</div>
							<div class="f1 ml-2">
								{{ template.name }}
								<small class=block>{{ template.pages.length }} page(s) </small>
							</div>
						</div>
					</a-select-option>
				</a-select>

			</a-form-model-item>

		</a-form-model>
	</div>
</template>

<script>
let rules = {
	type: [
		{ required: true, message: 'Please Select Transaction Type', trigger: 'blur' },
	],
	template: [
		{ required: true, message: 'Please choose a contract', trigger: 'blur' },
	],
	offerType: [
		{ required: true, message: 'Please choose an Offer Type', trigger: 'blur' },
	],
	region: [{ required: true, message: 'Please select Activity zone', trigger: 'change' }],
	date1: [{ required: true, message: 'Please pick a date', trigger: 'change' }],
	resource: [
		{ required: true, message: 'Please select activity resource', trigger: 'change' },
	],
	desc: [{ required: true, message: 'Please input activity form', trigger: 'blur' }],
}

export default {
	props: ['value', 'setManual', 'transaction', 'updateTransaction'],
	watch: {
		'step1.isManual'(val) {
			this.setManual(val)
		}
	},
	data: () => ({
		disableType: false,
		step1: {
			isManual: false,
			type: 'purchase',
			template: '',
			offerType: 'firm',
			grace: 1,
		},
		rules,
	}),
	methods: {
		validate() {
			let self = this
			if (!this.$refs.step1) {
				return;
			}
			this.$refs.step1.validate(valid => {
				if (valid) {
					self.$emit('done', this.step1)
				} else {
					return false;
				}
			})
		},
		transactionTypeChange(val) {
			this.step1.template = ''
		}
	},
	computed: {
		appSettings() {
			let app = this.$store.state.appData.allSettings.app;
			if (!app) app = { options: {} };
			return app.options;
		},
		integrated() {
			return this.$store.state.appData.vendors.docusign
		},
		cType() {
			let cType = 'Purchase'
			if (this.step1.type === 'lease') cType = 'Lease'
			return cType
		},
		transactionsFiltered() {
			return this.contractTemplates.filter(x => x.type === this.step1.type || (this.step1.type !== 'lease' && x.type === 'reservation'))
		},
		transactionTypes() {
			let lookup = {
				purchase: 'Purchase Agreement',
				lease: 'Lease Agreement'
			}
			let result = {}

			this.contractTemplates.forEach(x => {
				if (!lookup[x.type]) return null
				result[x.type] = {
					label: lookup[x.type],
					value: x.type,

				}
			})

			return Object.values(result)
		},
		contractTemplates() {
			let templates = this.$store.state.appData.templates || {}
			return Object.values(templates).filter(x => {
				return x.pages && x.pages.length
			})
		},

		filteredTemplates() {
			let templates = this.$store.state.appData.templates || {}
			return Object.values(templates).filter(t => {
				let filterCriteria = t.options?.filterCriteria
				return filterCriteria && (filterCriteria.frontage || filterCriteria.modelType || filterCriteria.stories)
			}).map(t => {
				return {
					id: t.id,
					filterCriteria: t.options.filterCriteria
				}
			})
		},
	},
	async created() {
		this.step1.type = this.value?.type || this.step1.type
		this.step1.template = this.value?.template || this.step1.template
		this.step1.offerType = this.value?.offerType || this.step1.offerType
		this.step1.isManual = !this.integrated.active || this.transaction.isManual || this.step1.isManual

		if (this.updateTransaction) {
			this.step1.type = this.transaction.type
			this.step1.offerType = this.transaction.offerType
			this.step1.grace = this.transaction.grace
			if (this.transaction && this.transaction.envelopes && this.transaction.envelopes[0]) {
				this.step1.isManual = this.transaction.envelopes[0].isManual;
				if (this.transaction.envelopes[0].file && this.transaction.envelopes[0].file.length && this.transaction.envelopes[0].file[0].template) {
					this.step1.template = this.transaction.envelopes[0].file[0].template
				}
			}
		}

		if (!this.step1.template) {
			const sParams = new URLSearchParams(location.search);
			const elevation = sParams.get('elevation');
			const packageId = sParams.get('package');
			const size = Number(sParams.get('size'));
			const type = sParams.get('type');

			// Auto Populate Template based on elevation or (frontage and modelType) and contract template filter criteria
			if (this.filteredTemplates?.length) {
				let template = null;

				const matchTemplate = (criteria, unitGroup, selectedPackage) => {
					const { frontage, modelType, stories } = criteria;
					const matchedFrontage = frontage ? frontage === unitGroup?.size : true;
					const matchedModelType = modelType ? modelType === unitGroup?.type : true;
					const matchedStories = stories ? stories === selectedPackage?.stories : true;

					return matchedFrontage && matchedModelType && matchedStories;
				};

				if (elevation) {
					this.$store.commit('LOAD', true);
					try {
						const { data } = await this.$api.get(`units/:instance/${elevation}`);
						const { unitGroup, packages } = data;
                        let selectedPackage = null;
                        if (packages?.length) {
                            const pkg = packages.find(p => p.id === packageId);
                            selectedPackage = pkg || null;
                        }

						template = this.filteredTemplates.find(t => matchTemplate(t.filterCriteria, unitGroup, selectedPackage));
					} catch (err) {
						console.error('An error occurred while fetching selected elevation details. Please try again.', err);
					} finally {
						this.$store.commit('LOAD', false);
					}
				} else if (size && type) {
					template = this.filteredTemplates.find(t => {
						const { frontage, modelType, stories } = t.filterCriteria;
						const matchedFrontage = frontage ? frontage === size : true;
						const matchedModelType = modelType ? modelType === type : true;
						const matchedStories = !stories;

						return matchedFrontage && matchedModelType && matchedStories;
					});
				}

				if (template) {
					this.step1.template = template.id;
				}
			}
		}
	},
	mounted() {
		if (this.transactionTypes.length === 1) {
			this.step1.type = this.transactionTypes[0].value
			this.disableType = true

		}

		if (this.appSettings.coolingOffDays) {
			this.step1.grace = this.appSettings.coolingOffDays
		}

		this.$emit('setFn', this.validate)

		// Adding timeout to calculate depended computed value properly
		setTimeout(() => {
			this.step1.isManual = this.transaction.isManual || this.step1.isManual
			this.step1.type = this.transaction.type || this.step1.type
			this.step1.template = this.transaction.template || this.step1.template
			this.step1.offerType = this.transaction.offerType || this.step1.offerType
			this.step1.grace = this.transaction.grace || this.step1.grace
		}, 500)
	}
}
</script>

<style>
.template-list i {
	font-size: 25px;
	padding: 6px;
	background: var(--light-purple);
	border-radius: 50%;
	width: 15px;
	height: 15px;
}
</style>
